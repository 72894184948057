@import '../../base.scss';

.modal{
    box-sizing: border-box;
    position: relative;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $background-footer;
    border-radius: 20px;
    padding: 30px;
    color: $white;
    z-index: 20;
}

.box{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.close_icon{
    position: absolute;
    top: 5px;
    right: 5px;
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    &:hover{
        cursor: pointer;
        filter: drop-shadow(0 0 5px $white);
        transition: .3s;
    }
}

.title{
    @include title;
    font-weight: 400;
    font-family:'Lovelace';
    color: $white;
}

.text{
    font-family:  "Montserrat", "Arial ", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    color: $orange;
    margin: 15px 0 15px;
}

.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.box_input{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 60px;
    background-color: $white;
    border-radius: 4px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, .25);
    border: 1px solid $text-box;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    box-sizing: border-box;
    width: 100%;
    color: $text-box;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 8px; 
}

.input{
    box-sizing: border-box;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
}

.box_textarea{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 100px;
    background-color: $white;
    border-radius: 4px;
    padding: 0 25px 10px;
    box-sizing: border-box;
    margin-top: 10px;
}

.label_textarea{
    width: 100%;
    color: $text-box;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px;
    height: 80px;
    overflow: hidden;
}

.textarea{
    box-sizing: border-box;
    resize: none;
    width: 100%;
    min-height: 80px;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
    margin-top: 5px;
}

.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $text-box #2F2F37;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: $text-box;
}
.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.button_form{
    @include button
}

.button_disabled{
    pointer-events: none;
    cursor: default;
    filter: grayscale(100%);
}

@media(max-width: 425px){

    .modal{
        padding: 20px;
    }

    .title{
        max-width: 250px;
        font-size: 20px;
        line-height: 24px;
    }
}