@import '../../base.scss';

    .main{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .box{
        background-color: $background-footer;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
    }

    .image{
        width: 200px;
        height: auto;
        position: absolute;
        top: -100px;
        left: 3%;
    }

    .box_info{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        width: 100%;
    }

    .title{
        font-family:'Lovelace';
        font-weight: 400;
        font-size: 38px;
        line-height: 1.2;
        text-align: center;
        color: $white;
        margin: 0;
        z-index: 1;
    }

    .subtitle{
        font-family:'Lovelace';
        font-weight: 400;
        font-size: 38px;
        line-height: 1.2;
        text-align: center;
        color: $orange;
        margin: 10px 0 0;
    }

    .button{
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        padding: 0;
        color: $white;
        max-width: 450px;
        width: 100%;
        height: 50px;
        margin: 30px 0 0;
        border-radius: 4px;
        background: #5E7285;
    
        &:hover{
            cursor: pointer;
            transform: scale( .90 );
            transition: .5s;
            background: $orange;
        }
    }

    @media(max-width: 1020px){
        .image{
            width: 150px;
            top: -75px;
            left: 1%;
        }

        .title{
            font-size: 30px;
        }
        
        .subtitle{
            font-size: 30px;
        }   
    }

    @media(max-width: 768px){

        .box_info{
            max-width: 400px;
        }

        .title{
            font-size: 30px;
        }
        
        .subtitle{
            font-size: 30px;
        }   
    }

    @media(max-width: 600px){
        .image{
            width: 100px;
            top: -50px;
        }
    }

    @media(max-width: 425px){
        .image{
            display: none;
        }
    }