@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column
}

.title{
    @include title
}

.list{
    max-width: 1200px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
}

.button{
    @include button
}