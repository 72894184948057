@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Advantages/img.webp);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
    background-color: rgba(247, 242, 235, .8);
}


.list{
    max-width: 1200px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.item_box{
    width: 93px;
    height: 93px;
    border-radius: 50%;
    background: linear-gradient(to left,#a9d05b 0,#85c406 50%,#a9d05b 100%);
    background-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}

.item_image{
    width: 100%;
    margin: 10px 0;
    aspect-ratio: 4/3; 
}

.item_title{
    font-size: 21px;
    line-height: 21px;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
    margin: 0;
    padding: 20px 0;
    color: $white;
    text-align: center;
    color: $title;
    background-color: $orange;
}

.button{
    max-width: 100%;
    width: 100%;
    height: 60px;
    border: 0;
    color: white;
    background-color: $background-footer;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    margin: 0;

    &:hover{
        cursor: pointer;
        background-color: $orange;
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}

.type_box{
    @include main-column;
    background-color: rgba(1, 15, 23, .7);
}

.image{
    width: 200px;
    height: auto;
    position: absolute;
    top: -50px;
    right: 3%;
}

.type_box_title{
    @include title;
    max-width: 1200px;
    width: 100%;
    text-align: left;
    z-index: 1;
    margin: 0 0 30px 0;
    color: $white;
}

.type{
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.type_item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 540px;
    width: 100%;
    background: $background-color;
    padding: 10px;
}

.type_image{
    width: 100%;
    height: auto;
}

.type_title{
    font-weight: 600;
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    color: $title;
    margin: 10px 0 0;
}

.type_subtitle{
    color: $title;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    max-width: 400px;
    margin: 10px 0;
}

.type_text{
    color: $title;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.2;
    margin: 0;
}

@media(max-width: 1020px){
    .type_box_title{
        max-width: 960px;
    }

    .type_title{
        font-size: 26px;
    }
}

@media(max-width: 768px){
    .list{
        gap: 10px;
    }

    .type_title{
        font-size: 20px;
    } 

    .item_title{
        font-size: 18px;
    }
}

@media(max-width: 1020px){
    .type_box_title{
        max-width: 960px;
    }

    .type_title{
        font-size: 26px;
    }
}

@media(max-width: 768px){
    .type_box{
        align-items: flex-start;
    }

    .type_box_title{
        max-width: 550px;
    }
}

@media(max-width: 600px){
    .list{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .item{
        background-color: $orange;
        padding: 20px;
    }

    .item_image{
        margin: 0 0 10px 0;
    }

    .type{
        flex-direction: column;
        align-items: center;
    }

    .image{
        width: 150px;
        top: -40px;
    }
}

@media(max-width: 425px){
    .image{
        display: none;
    }
}