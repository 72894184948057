@import '../../base.scss';

.main{
    @include main-column;
    background-color: $background-footer;
    padding: 50px 20px 100px;
}

.container{
    @include container-column;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.box{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo{
    width: 200px;
    height: auto;
    margin-bottom: 10px
}

.text{
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    width: 100%;
    margin: 0 0 10px;
    text-align: left;
}

.link_mail{
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    width: 100%;
    margin: 0 0 15px;
    text-decoration: none;
    color: $white;
    &:hover{
        color: $orange;
        transition: .3s;
    }
}

.list{
    max-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 50px 20px 0;
}

.item{
    box-sizing: content-box;
}

.link{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image{
    width: 30px;
    height: 30px;
    &:hover {
        width: 35px;
        height: 35px; 
        cursor: pointer;
        filter: drop-shadow(0 0 5px $white);
        transition: all .3s ease-out;
    }
}

.link_developer{
    text-decoration: none;
    color: $white;
    & :hover{
        opacity: .5;
        transition: .3s;
    }
    & span{
        color: $red;
        font-weight: 700;
    }
}

@media(max-width: 1028px){
    .box{
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media(max-width: 768px){

    .main{
        padding: 30px 20px 70px;
    }

    .container{
        @include container-column;
            flex-direction: column;
            align-items: flex-start;
    }

    .box{
        max-width: 100%;
    }

    .logo{
        width: 150px;
    }
}

@media(max-width: 425px){
    .text{
        font-size: 14px;
        line-height: 24px;
    }
}