@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    max-width: 1000px;
}

.box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_info{
    width: 60%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.title{
    @include title;
    text-align: left;
}

.text{
    color: $title;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    max-width: 400px;
    margin: 10px 0;
}


.button{
    @include button;
}


.box_image{
    width: 40%;
    display: flex;
    justify-content: center;
}

.image{
    width: 70%;
    height: auto;
}


@media(max-width: 1020px){
    .title{
        max-width: 480px;
    }
}

@media(max-width: 768px){
    .title{
        max-width: 410px;
    }

    .text{
        font-size: 16px;
    }
}

@media(max-width: 610px){
    .box{
        flex-direction: column;
    }

    .box_info{
        width: 100%;
        align-items: center;
    }

    .title{
        text-align: center;
    }

    .text{
        text-align: center;
    }

    .box_image{
        width: 100%;
        margin-top: 30px;
    }
}

@media(max-width: 425px){
    .image{
        width: 90%;
    }
}



