@import '../../base.scss';

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
        
    }
}

.image{
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
}

.text{
    width: 100%;
    color: $background-footer;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    margin: 5px 0 0;
}