@import '../../base.scss';

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: fixed;
    z-index: 5;
    top: 0px;
}

.header_scroll {
    background-color: $background-header;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 20px;
}

.logo{
    width: 200px;
    height: auto;
}

.nav{
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 30px 0 50px;
}

.link{
    color: $white;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-out;

    &:hover{
        cursor: pointer;
        transition: all .3s ease-out;
    }

    &::before{
        background: $orange;
        position: absolute;
        content: '';
        top: 20px;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: all .3s ease-out;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}

.button  {
    display: none;
}

.box_phone{
    display: none;
}

.link_phone{
    width: 100%;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;

    &:hover{
        color: $orange;
        transition: .3s;
    }

    &:last-child{
        margin-top: 5px;
    }
}

.icon{
    display: none;
}

@media(max-width: 768px){


    .box{
        justify-content: space-between;
    }

    .logo{
        width: 150px;
    }

    .nav{
        display: none;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
    }

    .box_phone{
        min-width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width: 425px){

    .logo{
        display: none;
    }

    .icon{
        display: block;
        width: 40px;
        height: 40px;
    }
}