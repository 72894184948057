@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px 20px;
    gap: 20px;
}

.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.title{
    font-size: 200px;
    line-height: 1;
    color: $title;
    margin: 0;
}

.subtitle{
    @include title;
    max-width: 300px;
    text-align: left;
}

.text{
    color: $title;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    margin: 10px 0;
}

.list{
    max-width: 60%;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
}

.item{
    display: flex;
    align-items: flex-start;
}


.icon{
    width: 80px;
    height: 80px;
}

.box_info{
    box-sizing: border-box;
}

.item_title{
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
    color: $title;
    margin: 50px 0 0;
}

.item_text{
    color: $title;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;
}



@media(max-width: 1020px){
    .subtitle{
        max-width: 250px;
    }

    .text{
        font-size: 16px;
    }

    .list{
        max-width: 68%;
        gap: 10px;
        
    }
}

@media(max-width: 768px){
    .title{
        font-size: 150px;
    }

    .container{
        flex-direction: column;
        padding: 40px 10px;
    }

    .list{
        max-width: 100%;
    }

    .subtitle{
        max-width: 200px;
    }

    .text{
        font-size: 16px;
    }
}


@media(max-width: 425px){

    .title{
        font-size: 100px;
    }

    .container{
        padding: 30px 10px;
    }
}