@import '../../base.scss';


.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    width: 100%;
    position: relative;
}

.item{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    padding: 20px;
    background-image: url(../../image/ListBoilers/background.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image{
    width: 100px;
    height: auto;
}

.title{
    height: 72px;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    color: $white;
    margin: 10px 0 0;
}

.info{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.box{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.circle{
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $orange;
    margin-right: 10px;
}

.text{
    margin: 0 0 15px;
}

@media(max-width: 1020px){
    .title{
        height: 72px;
        font-size: 26px;
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media(max-width: 768px){
    .title{
        font-size: 22px;
    }
}

@media(max-width: 600px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}