@import '../../base.scss';


@keyframes arrow-down {
    0%{
        transform: rotate(45deg) translate(0, 0);
    }
    50%{
        transform: rotate(45deg) translate(15px, 15px);
    }
    100%{
        transform: rotate(45deg) translate(0, 0);
    }
}

    .main {
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-image: url(../../image/InitialPage/image.webp);
        background-repeat: no-repeat;
        background-position: center left;
        background-size: cover;
        position: relative;
        overflow: hidden;
        padding: 0 50px;
    }

    .overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(1, 15, 23, .5);
        top: 0;
    }

.container{
    box-sizing: border-box;
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
}

.title{
    font-family:'Lovelace';
    max-width: 800px;
    width: 100%;
    font-size: 62px;
    line-height: 1.2;
    text-align: left;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
}

.line{
    background: $orange;
    height: 2px;
    width: 50px;
    margin: 20px 0 15px; 
}

.text{
    font-size: 22px;
    line-height: 1.4;
    font-weight: 400;
    text-align: left;
    margin: 0
}


.button{
    @include button;
}

@media(max-width: 768px){
    .main {
        padding: 0 20px;
    }

    .title{
        max-width: 550px;
        font-size: 52px;
    }
    
    .line{
        width: 40px;
    }
    
    .text{
        font-size: 17px;
    }
}

@media(max-width: 560px){

    .title{
        max-width: 400px;
        font-size: 39px;
    }
}

@media(max-width: 425px){
    .main {
        padding: 0 10px;
    }

    .container{
        max-width: 350px;
    }

    .title{
        font-size: 30px;
    }
    
    .line{
        width: 30px;
    }
}

