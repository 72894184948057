@import '../../base.scss';

.main{
    @include main-column;
    padding: 20px;
}

.box{
    @include box-column
}

.title{
    @include title;
}

.list{
    max-width: 1200px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
}

.button{
    @include button;
}

.link{
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    border: none;
    padding: 0;
    color: $white;
    margin: 30px 0 0;
    border-radius: 4px;
    background: $orange;
    padding: 20px;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
    }
}